<template lang="pug">
div.video_record.fill
  div#ask_title
    span {{ introVideoCTA }}
  div.select_input_device_container(v-show="showSelectDevices")
    div.multiselect_dark
      Multiselect(
        v-model="selectedVideoLabel",
        v-bind:options="inputVideoDeviceLabels",
        :searchable="false",
        :close-on-select="true",
        :show-labels="false"
        placeholder="Select video input"
      )
    div.multiselect_dark
      Multiselect(
        v-model="selectedAudioLabel",
        v-bind:options="inputAudioDeviceLabels",
        :searchable="false",
        :close-on-select="true",
        :show-labels="false"
        placeholder="Select audio input"
      )
  div.video_controls
    input#keyshortcut.hideaway(v-on:keyup="keySpaceToSubmit")
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
    div.wrapper(v-show="!loading")
      img.start(v-show="!isRecording" v-on:click="startRecording" ref="start" style="touch-action: manipulation" :src="recordStartButtonSrc" :title="recordButtonTitle")
      img.start(v-show="hasRecording && !isPlaying" v-on:click="playRecording" ref="play" style="touch-action: manipulation" :src="playButtonSrc")
      div.stop_recording_container(v-show="isRecording || (hasRecording && isPlaying)" v-on:click="stopRecording" ref="stop" style="touch-action: manipulation")
        div#countdown_container(v-show="showTimer")
          CountdownTimer(
            :startTime="recordingLimit"
            v-bind:run="runTimer"
            v-on:timerEnd="timerEnd"
          )
        img.stop(:src="stopButtonSrc" :title="stopButtonTitle")
      img.next(v-show="hasRecording" v-on:click="saveRecording" ref="save" style="touch-action: manipulation" :src="nextButtonSrc" :title="nextButtonTitle")
      img.settings(v-show="showSettings" v-on:click="showSelectDevices = !showSelectDevices" ref="settings" style="touch-action: manipulation" :src="settingsIconSrc" :title="settingsTitle")

  video#playback(autoplay
        playsinline
        loop
        )
  video#preview(autoplay
        playsinline
        muted
        )


</template>

<script>

import { SLACK_USER_UPLOAD_INTRO_VIDEO_MUTATION } from '../graphql/mutations'
import Multiselect from 'vue-multiselect'
import CountdownTimer from '../components/CountdownTimer'
import config from '../appConfig'

export default {
  name: 'SlackUserAddIntroVideo',
  props: {
    token: String
  },
  components: {
    Multiselect,
    CountdownTimer,
  },
  computed: {
    introVideoCTA () {
      return this.$route.query.prompt ? this.$route.query.prompt : process.env.VUE_APP_INTRO_VIDEO_CTA
    },
    nextButtonTitle () {
      return "Save recording"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    settingsIconSrc () {
      return process.env.BASE_URL + "video_settings_button.svg"
    },
    settingsTitle () {
      return "change video/audio device input"
    },
    recordButtonTitle () {
      if (this.hasRecording) { return "Re-Record" }
      return "Record"
    },
    stopButtonTitle () {
      if (this.hasRecording) { return "Pause playback" }
      return "Stop recording"
    },
    nextButtonSrc () {
      return process.env.BASE_URL + "video_save_button.svg"
    },
    recordStartButtonSrc () {
      return process.env.BASE_URL + "record_start_button.svg"
    },
    playButtonSrc () {
      return process.env.BASE_URL + "play_button.svg"
    },
    stopButtonSrc () {
      if (this.isRecording) {
        return process.env.BASE_URL + "record_stop_button.svg"
      } else if (this.hasRecording) {
        const video = document.querySelector('video#playback')
        if (video.paused) {
          return process.env.BASE_URL + "playback_stop_button.svg"
        }

      }
      return process.env.BASE_URL + "playback_stop_button.svg"
    },
    showSettings () {
      return !config.isSafari
    },
    isSafari () {
      return config.isSafari
    },
    recordingLimit () {
      return config.recordLimitInSecs
    },
    inputVideoDevices () {
      return this.inputDevices.filter(device => device.kind == "videoinput")
    },
    inputAudioDevices () {
      return this.inputDevices.filter(device => device.kind == "audioinput")
    },
    inputVideoDeviceLabels () {
      return this.inputVideoDevices.map(device => device.label)
    },
    inputAudioDeviceLabels () {
      return this.inputAudioDevices.map(device => device.label == "" ? "internal" : device.label)
    },
  },
  watch: {
    selectedVideoLabel: function() {
      const selectedVideoDevice = this.inputVideoDevices.find(d => d.label == this.selectedVideoLabel)
      this.allStop()
      this.mediaDeviceConstraints.video.deviceId = {exact: selectedVideoDevice.deviceId}
      this.setupVideoPreview()
    },
    selectedAudioLabel: function() {
      const selectedAudioDevice = this.inputAudioDevices.find(d => d.label == this.selectedAudioLabel)
      this.allStop()
      this.mediaDeviceConstraints.audio.deviceId = {exact: selectedAudioDevice.deviceId}
      this.setupVideoPreview()
    },
    participant: function () {
      // this.$store.commit('setProject', this.participant.project)
    },
  },
  data () {
    return {
      showSelectDevices: false,
      showTimer: false,
      runTimer: false,
      inputDevices: [],
      selectedVideoLabel: null,
      selectedAudioLabel: null,
      isRecording: false,
      isPlaying: false,
      hasRecording: false,
      recordedBlobs: [],
      mediaRecorder: null,
      loading: false,
      mediaDeviceConstraints: config.mediaDeviceConstraints,
    }
  },
  apollo: {

  },
  methods: {
    keySpaceToSubmit: function(e) {
      // console.log(e.keyCode)
      if (e.keyCode === 32) {
        if (this.isRecording) {
          this.stopRecording()
        } else {
          this.startRecording()
        }
      }
    },
    timerEnd: function () {
      this.stopRecording()
    },
    getDevices: function() {
      navigator.mediaDevices.enumerateDevices().then((inputDevices) =>{
        this.inputDevices = inputDevices
      }).catch((error) => {
        console.log(error)
      })
    },
    addVideo: async function(file) {
      this.loading = true
      this.$apollo.mutate({
        mutation: SLACK_USER_UPLOAD_INTRO_VIDEO_MUTATION,
        variables: {
          authenticationToken: this.token,
          file: file,
        },
        context: {
          hasUpload: true
        },
      }).then((data) => {
        // console.log(data)
        const success = data.data.slackUserUploadIntroVideo.success
        const errors = data.data.slackUserUploadIntroVideo.errors
        this.loading = false

        if (success) {
          this.next()
        } else {
          this.errorsToast(errors)
        }
      }).catch((error) => {
        console.error(error)
        this.loading = false
        this.errorToast(error)
      })
    },
    saveRecording: function() {
      const fileName = "slack_user_intro_video_"+this.token+".mp4"
      const file = new File(this.recordedBlobs, fileName, {type: this.recordedBlobs[0].type})
      this.addVideo(file)
    },
    handleDataAvailable: function(event) {
      // console.log('handleDataAvailable', event)
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data)
      }
    },
    loadRecording: function () {
      const superBuffer = new Blob(this.recordedBlobs, {type: 'video/webm'});
      const video = document.querySelector("video#playback")
      video.srcObject = null
      video.src = window.URL.createObjectURL(superBuffer)
      video.controls = false
      video.loop = true
      video.muted = false
      video.load()
      video.pause()
    },
    playRecording: function() {
      // console.log('----------playRecording')
      const video = document.querySelector("video#playback")

      var playPromise = video.play()
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Automatic playback started!
          // console.log('playing')
          // video.loop = true
          this.isPlaying = true
        }).catch(function(error) {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
          console.error(error)
        })
      }
    },
    stopRecording: function() {
      document.querySelector('#keyshortcut').focus()
      this.runTimer = false
      this.showTimer = false

      if (this.hasRecording) {
        var video = document.querySelector("video#playback")
        video.pause()
        this.isPlaying = false
      } else {
        if (this.mediaRecorder != null) this.mediaRecorder.stop()
        this.hasRecording = true
        this.isRecording = false
      }
    },
    resetPlayback: function() {
      this.recordedBlobs = []
      this.hasRecording = false
      var video = document.querySelector("video#playback")
      if (video.src) {
        window.URL.revokeObjectURL(video.src)
        video.removeAttribute('src')
        video.load()
      }
    },
    startRecording: function() {
      this.resetPlayback()
      document.querySelector('#keyshortcut').focus()

      let options = config.recordingOptions
      try {
        this.mediaRecorder = new MediaRecorder(window.stream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        return;
      }

      // console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
      this.mediaRecorder.onstop = (event) => {
        // console.log('Recorder stopped: ', event);
        // console.log('Recorded Blobs: ', this.recordedBlobs);
        if (event.type == "stop") {
          this.loadRecording()
          // console.log('stopped')
        }
      }
      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start()
      this.isRecording = true
      // console.log('MediaRecorder started', this.mediaRecorder);

      this.runTimer = true
      this.showTimer = true
    },
    setupVideoPreview: async function() {

      try {
        const stream = await navigator.mediaDevices.getUserMedia(this.mediaDeviceConstraints)
        var video = document.querySelector("video#preview")
        video.muted = true // otherwise audio feedback will occur
        video.srcObject = stream
        window.stream = stream  // make stream available to console
        // console.log("getUserMedia() got stream:", stream)
      } catch (e) {
        console.error('navigator.mediaDevices.getUserMedia error:', e)
        if (/Permission denied/i.test(e)) {
          this.$toast.error("You have blocked this page from accessing your camera and microphone.  In the address bar, look for the icon that shows site permissions, and click to approve camera and microphone access and reload the page.",{timeout: false})
        }
      }

    },
    stopStreaming: function() {
      // untested alternate way
      // function stop() {
      //   if (window.stream) {
      //     window.stream.getTracks().forEach(function(track) {
      //       track.stop()
      //     })
      //   }
      // }

      var video = document.querySelector("video#preview")
      const stream = video.srcObject

      if (stream == null) return

      const tracks = stream.getTracks()

      tracks.forEach(function(track) {
        track.stop();
      });

      video.srcObject = null;
    },
    openFullscreen: function() {
      var elem = document.querySelector(".video_record")

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    allStop: function() {
      this.stopStreaming()
      this.stopRecording()
      this.resetPlayback()
    },
    next: function() {
      this.$router.push({name:"Done"})
    },
  },
  beforeRouteLeave(to, from, next) {
    this.allStop()
    next()
  },
  beforeUnmount: function() {
    this.allStop()
  },
  mounted: function () {
    // window.vue = this
    // console.log(this.slug)
    // console.log(this.participant)
    this.setupVideoPreview()
    this.getDevices()
    document.querySelector('#keyshortcut').focus()
  }
}
</script>

<style scoped>
.video_record {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
}

.overlay {
  display: block;
  position: absolute;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

#playback {
  z-index: 100
}

#preview {
  z-index: 50
}


#ask_title {
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  width: 96%;
  padding: 0 2%;
  position: relative;
  margin-top: 20%;
  z-index: 1000;
  text-shadow: 4px 4px 6px rgba(0,0,0,0.30);
}

.fill {
  width: 100%;
  height: 100%;
}

.link_skip {
  position: absolute;
  bottom: -25px;
  width: 100%;
  text-align: center;
}

.title {
  position: absolute;
  z-index: 500;
  top: 45%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 0 2px 40px rgba(0,0,0,0.45);
}

.multiselect_dark {
  border: 2px solid rgba(255,255,255,0.8);
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0 2px 10px rgba(0,0,0,0.50);
  box-shadow: 0 2px 10px rgba(0,0,0,0.20);
  margin-bottom: 10px;
}


#countdown_container {
  position: absolute;
  z-index: 800;
  top: 0px;
  left: 9px;
  width: 80px;
  height: 80px;
}

.settings {
  cursor: pointer;
  display: block;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 10%;
  left: 2%;
}


.stop_recording_container {
  display: inline-block;
  position: relative;
}
</style>
