<template lang="pug">
div.view_container
  Header
  SlackUserAddIntroVideo(
    v-if="!isSafari && !isIOS"
    v-bind:token="token"
  )
  SlackUserAddIntroVideoWithFileUpload(
    v-if="isIOS"
    v-bind:token="token"
  )
</template>

<script>
/* global gtag */
import config from '../appConfig'
import SlackUserAddIntroVideo from '../components/SlackUserAddIntroVideo'
import SlackUserAddIntroVideoWithFileUpload from '../components/SlackUserAddIntroVideoWithFileUpload'
import Header from '../components/Header'

export default {
  name: 'SlackUserAddIntroVideoContainer',
  props: {
    token: String
  },
  components: {
    SlackUserAddIntroVideo,
    SlackUserAddIntroVideoWithFileUpload,
    Header,
  },
  data () {
    return {
      isIOS: false,
      isSafari: false,
    }
  },
  apollo: {

  },
  methods: {

  },
  beforeMount: function () {
    this.isSafari = config.isSafari
    this.isIOS = config.isIOS
  },
  mounted: function() {
    // window.vue = this

    if (this.isSafari && !this.isIOS) {
      this.$toast.warning(
        "We do not support Safari at this time. We recommend chrome:  https://www.google.com/chrome",
        {timeout: false}
      )
    }
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/slack/user/add_intro_video'})
  },
}
</script>

<style>

#nav {
  display:none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
